<script>
  import CurvedHeader from '@/components/general/curved-header/CurvedHeader.vue';
  import ExplorerScreen from '@/components/courses/explorer-screen/ExplorerScreen.vue';
  import CoursesSearch from '@/components/courses/courses-search/CoursesSearch.vue';
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    name: 'explore',
    data() {
      return {
        isLoading: false,
        isSearchInit: false,
        shouldOpenDialogBeforeSearchInit: false,
      };
    },
    components: {
      CurvedHeader,
      ExplorerScreen,
      CoursesSearch,
    },
    computed: {
      ...mapGetters('searchModule', ['getSearchKeyword']),
    },
    methods: {
      ...mapMutations('searchModule', ['setSearchKeyword']),
      initSearchAndOpenDialog() {
        this.isSearchInit = true;
      },
      initSearch(openDialog = false) {
        this.isSearchInit = true;
        this.shouldOpenDialogBeforeSearchInit = openDialog;
      },
      terminateSearch() {
        this.isSearchInit = false;
        this.shouldOpenDialogBeforeSearchInit = false;
        this.setSearchKeyword('');
      },
    },
    watch: {
      getSearchKeyword: {
        handler(val) {
          if (val) this.isSearchInit = !!val;
        },
        immediate: true,
      },
    },
  };
</script>
