<template>
  <div class="px-12 py-16 my-4 relative">
    <v-btn
      v-if="isSearchInit"
      class="back-btn"
      @click="terminateSearch"
      icon>
      <v-icon color="primaryColor">mdi-arrow-left</v-icon>
    </v-btn>
    <h1 class="section-title">Explore</h1>
    <ExplorerScreen
      v-if="!isSearchInit"
      @initSearch="initSearch" />
    <CoursesSearch
      v-else
      :shouldOpenDialogBeforeSearchInit="shouldOpenDialogBeforeSearchInit" />
  </div>
</template>

<script>
  import ExploreMixin from '@/views/explore/Explore.mixin.vue';
  export default {
    mixins: [ExploreMixin],
  };
</script>

<style lang="css" scoped>
  .section-title {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: var(--v-offBlack-base);
  }
  .back-btn {
    position: absolute;
    top: 16px;
    left: 36px;
  }
</style>
